
import _suite1 from '../assets/img/bedrooms/suite1.jpg';
import _duplocasal1 from '../assets/img/bedrooms/duplocasal1.jpg';
import _duplosolteiro1 from '../assets/img/bedrooms/duplosolteiro1.jpg';
import _triplosolteiro1 from '../assets/img/bedrooms/triplosolteiro1.jpg';


export default [
    {
        images: [
            _suite1
        ],
        name: {
            pt_br: "Suíte",
            en_us: "...",
            es_es: "..."
        },
        category: {
            pt_br: "Suíte",
            en_us: "...",
            es_es: "..."
        },
        convenience: {
            pt_br: "Banheiro privativo, TV de tela plana, frigobar e telefone",
            en_us: "...",
            es_es: "..."
        }
    }, {
        images: [
            _duplocasal1
        ],
        name: {
            pt_br: "Duplo / Casal",
            en_us: "...",
            es_es: "..."
        },
        category: {
            pt_br: "Luxo",
            en_us: "...",
            es_es: "..."
        },
        convenience: {
            pt_br: "Banheiro privativo, TV de tela plana, frigobar e telefone",
            en_us: "...",
            es_es: "..."
        }
    }, {
        images: [
            _duplosolteiro1
        ],
        name: {
            pt_br: "Duplo Solteiro",
            en_us: "...",
            es_es: "..."
        },
        category: {
            pt_br: "Luxo",
            en_us: "...",
            es_es: "..."
        },
        convenience: {
            pt_br: "Banheiro privativo, TV de tela plana, frigobar e telefone",
            en_us: "...",
            es_es: "..."
        }
    }, {
        images: [
            _triplosolteiro1
        ],
        name: {
            pt_br: "Triplo Solteiro",
            en_us: "...",
            es_es: "..."
        },
        category: {
            pt_br: "Luxo",
            en_us: "...",
            es_es: "..."
        },
        convenience: {
            pt_br: "Banheiro privativo, TV de tela plana, frigobar e telefone",
            en_us: "...",
            es_es: "..."
        }
    }
];