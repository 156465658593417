
import './style.css';

import Translations from '../../data/Translations';
import BedroomsArray from '../../data/Bedrooms';
import { useState } from 'react';
import Slider from '../../components/Slider/Slider';
import LazyImage from '../../components/LazyImage/LazyImage';

const Bedrooms = ({ currentLanguage }) => {
    const [activedSlider, setActivedSlider] = useState(false);
    const [sliderIndex, setSliderIndex] = useState(0);
    const [imagesArray, setImagesArray] = useState([]);

    const handleSliderChange = (actived, index = 0) => {
        setActivedSlider(actived);

        if (index < 0) {
            index = imagesArray.length - 1;
        } else if (index > imagesArray.length - 1) {
            index = 0;
        }

        setSliderIndex(index);
    }

    return (
        <>
            <section id="bedrooms" className="bedrooms section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>{Translations.accommodations[currentLanguage]}</h2>
                        <p>{Translations.accommodationsDescription[currentLanguage]}</p>
                    </div>

                    <div className="row">

                        {
                            BedroomsArray.map((item, index) => {
                                return (
                                    <div className="col-lg-6">
                                        <div
                                            className="member d-flex align-items-start" 
                                            onClick={_ => {
                                                setImagesArray(item.images);
                                                handleSliderChange(true);
                                            }}
                                        >
                                            <div className="pic">
                                                <LazyImage
                                                    src={item.images[0]}
                                                    alt={item.name[currentLanguage]}
                                                />
                                            </div>
                                            
                                            <div className="member-info">
                                                <h4>{item.name[currentLanguage]}</h4>

                                                <span>
                                                    <b>{Translations.category[currentLanguage]}: </b>
                                                    {item.category[currentLanguage]}
                                                </span>

                                                <p>
                                                    <b>{Translations.convenience[currentLanguage]}: </b>
                                                    {item.convenience[currentLanguage]}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }

                    </div>
                </div>
            </section>

            <Slider actived={activedSlider} activeIndex={sliderIndex} imagesArray={imagesArray} currentLanguage={currentLanguage} handleSliderChange={handleSliderChange} />
        </>
    )
}

export default Bedrooms;