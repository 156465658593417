import Translations from '../../data/Translations';
import './style.css';

const Services = ({ currentLanguage }) => {
    return (
        <>
            <section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>{Translations.services[currentLanguage]}</h2>
                        <p>{Translations.servicesDescription[currentLanguage]}</p>
                    </div>

                    <div className="row horizontal-scroll-on-small">
                        <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-bed"></i></div>
                                <h4>{Translations.accommodations[currentLanguage]}</h4>
                                <p>{Translations.accommodationsDescription[currentLanguage]}</p>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-wifi"></i></div>
                                <h4>{Translations.wifi[currentLanguage]}</h4>
                                <p>{Translations.wifiDescription[currentLanguage]}</p>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="far fa-clock"></i></div>
                                <h4>{Translations._24hourService[currentLanguage]}</h4>
                                <p>{Translations._24hourServiceDescription[currentLanguage]}</p>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-car"></i></div>
                                <h4>{Translations.parking[currentLanguage]}</h4>
                                <p>{Translations.parkingDescription[currentLanguage]}</p>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="far fa-credit-card"></i></div>
                                <h4>{Translations.cards[currentLanguage]}</h4>
                                <p>{Translations.cardsDescription[currentLanguage]}</p>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-paw"></i></div>
                                <h4>{Translations.petFriendly[currentLanguage]}</h4>
                                <p>{Translations.petFriendlyDescription[currentLanguage]}</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default Services;