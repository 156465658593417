
export default {
    enterpriseName: "Tio San Hotel",
    address: {
        icon: "fas fa-map-marker-alt",
        text: {
            pt_br: "Endereço",
            en_us: "Address",
            es_es: "Dirección"
        },
        values: {
            street: {
                pt_br: "BR 163 - Km 18",
                en_us: "BR 163 - Km 18",
                es_es: "BR 163 - Km 18"
            },
            city: "Mundo Novo, MS",
            country: {
                pt_br: "Brasil",
                en_us: "Brazil",
                es_es: "Brasil"
            },
            googleMapsLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3647.110243445944!2d-54.28792968501547!3d-23.921149984505508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x948b4d15ef3e57e9%3A0xfb968cd2699a8f35!2sTio%20San%20Hotel!5e0!3m2!1spt-BR!2sbr!4v1637715816922!5m2!1spt-BR!2sbr"
        }
    },
    contact: {
        email: {
            icon: "fas fa-envelope",
            value: "reservas@hoteltiosan.com.br",
            text: {
                pt_br: "E-mail",
                en_us: "E-mail",
                es_es: "E-mail"
            }
        },
        phone: {
            icon: "fas fa-phone-alt",
            value: "(67) 99216-7227 / 99246-3873",
            text: {
                pt_br: "Telefone",
                en_us: "Phone",
                es_es: "Teléfono"
            }
        }
    },
    socialLinks: [
        {
            name: "WhatsApp",
            icon: "fab fa-whatsapp",
            link: "https://api.whatsapp.com/send?phone=556734742025",
            toShow: true,
            toShowOnFloatingActionBar: true
        }, {
            name: "Telegram",
            icon: "fab fa-telegram-plane",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Instagram",
            icon: "fab fa-instagram",
            link: "https://www.instagram.com/tio_san_hotel/",
            toShow: true,
            toShowOnFloatingActionBar: false
        }, {
            name: "Facebook",
            icon: "fab fa-facebook-f",
            link: "https://www.facebook.com/hoteltiosan",
            toShow: true,
            toShowOnFloatingActionBar: false
        }, {
            name: "Twitter",
            icon: "fab fa-twitter",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Skype",
            icon: "fab fa-skype",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "LinkedIn",
            icon: "fab fa-linkedin",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "YouTube",
            icon: "fab fa-youtube",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }
    ],
    developer: {
        link: "http://www.lotusdesenvolvimentos.com.br/",
        name: {
            pt_br: "Lotus - Desenvolvimento de Sistemas",
            en_us: "Lotus - Systems Development",
            es_es: "Lotus - Desarrollo de Sistemas"
        },
        text: {
            pt_br: "Desenvolvido por",
            en_us: "Developed by",
            es_es: "Desarrollado por"
        }
    }
}
