
import Constants from './Constants';

export default {
    selectLanguage: {
        pt_br: "Selecione um idioma",
        en_us: "Select a language",
        es_es: "Selecciona un idioma"
    },
    phone: {
        pt_br: "Telefone",
        en_us: "Phone",
        es_es: "Teléfono"
    },
    home: {
        pt_br: "Início",
        en_us: "Home",
        es_es: "Inicio"
    },
    services: {
        pt_br: "Serviços",
        en_us: "Services",
        es_es: "Servicios"
    },
    about: {
        pt_br: "Sobre",
        en_us: "About",
        es_es: "Sobre"
    },
    accommodations: {
        pt_br: "Acomodações",
        en_us: "Accommodations",
        es_es: "Alojamientos"
    },
    internalLinks: {
        pt_br: "Links Internos",
        en_us: "Internal Links",
        es_es: "Vínculos Internos"
    },
    ourSocialNetworks: {
        pt_br: "Nossas mídias sociais",
        en_us: "Our social networks",
        es_es: "Nuestras redes sociales"
    },
    ourSocialNetworksDescription: {
        pt_br: "Entre em contato conosco através de uma de nossas mídias sociais",
        en_us: "Get in touch with us through one of our social media",
        es_es: "Póngase en contacto con nosotros mediante nuestras redes sociales."
    },
    copyright: {
        pt_br: "© Copyright 2021. Todos os direitos reservados",
        en_us: "© Copyright 2021. All Rights Reserved",
        es_es: "© Copyright 2021. Todos los derechos reservados"
    },
    welcomeTitle: {
        pt_br: `Bem-vindo ao ${Constants.enterpriseName}`,
        en_us: "...",
        es_es: "..."
    },
    welcomeDescription: {
        pt_br: "Venha conhecer nosso hotel e aproveitar conosco.\nEstamos a disposição para lhe oferecer o melhor serviço da região!",
        en_us: "...",
        es_es: "..."
    },
    servicesDescription: {
        pt_br: "Estamos prontos para lhe atender com serviços de auto padrão e quartos confortáveis em um ambiente completamente tranquilo",
        en_us: "...",
        es_es: "..."
    },
    accommodationsDescription: {
        pt_br: "Possuímos apartamentos simples e suítes, todos com ar condicionado, TV de tela plana, internet, frigobar, cama box e telefone",
        en_us: "...",
        es_es: "..."
    },
    wifi: {
        pt_br: "Wi-Fi",
        en_us: "...",
        es_es: "..."
    },
    wifiDescription: {
        pt_br: "Internet de alta velocidade para conectar-se a qualquer momento",
        en_us: "...",
        es_es: "..."
    },
    _24hourService: {
        pt_br: "Atendimento 24 horas",
        en_us: "...",
        es_es: "..."
    },
    _24hourServiceDescription: {
        pt_br: "Atendimento disponível a qualquer horário do dia ou da noite",
        en_us: "...",
        es_es: "..."
    },
    parking: {
        pt_br: "Estacionamento",
        en_us: "...",
        es_es: "..."
    },
    parkingDescription: {
        pt_br: "Contamos com garagem e estacionamento rotativo",
        en_us: "...",
        es_es: "..."
    },
    cards: {
        pt_br: "Cartões",
        en_us: "...",
        es_es: "..."
    },
    cardsDescription: {
        pt_br: "Aceitamos cartões de crédito e débito de todas as bandeiras",
        en_us: "...",
        es_es: "..."
    },
    petFriendly: {
        pt_br: "Pet Friendly",
        en_us: "...",
        es_es: "..."
    },
    petFriendlyDescription: {
        pt_br: "Aqui você e seu pet são muito bem-vindos!",
        en_us: "...",
        es_es: "..."
    },
    category: {
        pt_br: "Categoria",
        en_us: "...",
        es_es: "..."
    },
    convenience: {
        pt_br: "Comodidade",
        en_us: "...",
        es_es: "..."
    },
    aboutDescription: {
        pt_br: "Conheça um pouco mais sobre nós!",
        en_us: "...",
        es_es: "..."
    }
}
