import './style.css';

import backgroundImageHome from '../../assets/img/front_1.jpg';
import Translations from '../../data/Translations';


const Home = ({ currentLanguage }) => {
    return (
        <>
            <section id="home" className="d-flex align-items-center" style={{backgroundImage: `url(${backgroundImageHome})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            <h1>{Translations.welcomeTitle[currentLanguage]}</h1>

                            {
                                Translations.welcomeDescription[currentLanguage].split("\n").map((item, index) => {
                                    return (
                                        <h2 key={index}>{item}</h2>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;