
import './style.css';

import Constants from '../../data/Constants';
import Translations from '../../data/Translations';
import LanguagesTrigger from '../Languages/LanguagesTrigger';
import { useState } from 'react';

const Header = ({currentLanguage}) => {
    const [isNavbarMobile, setIsNavbarMobile] = useState(false);

    return (
        <>
            <section id="topbar" />

            <header id="header" className="fixed-top ">
                <div className="container d-flex align-items-center">

                    <h1 className="logo me-auto"><a href="/">{Constants.enterpriseName}</a></h1>
                    {/* Uncomment below if you prefer to use an image logo */}
                    {/* <a href="/" className="logo me-auto"><img src="image/path" /></a> */}

                    <nav id="navbar"className={`navbar ${isNavbarMobile ? 'navbar-mobile' : ''}`}>
                        <ul>
                            <li><a className="nav-link scrollto" href="#topbar" onClick={_ => setIsNavbarMobile(false)}> {Translations.home[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#services" onClick={_ => setIsNavbarMobile(false)}> {Translations.services[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto" href="#bedrooms" onClick={_ => setIsNavbarMobile(false)}> {Translations.accommodations[currentLanguage]} </a></li>
                            <li><a className="nav-link scrollto " href="#about" onClick={_ => setIsNavbarMobile(false)}> {Translations.about[currentLanguage]} </a></li>
                        </ul>

                        {/* <LanguagesTrigger currentLanguage={currentLanguage} /> */}
                        
                        <i className={`mobile-nav-toggle ${isNavbarMobile ? 'actived fas fa-times' : 'fas fa-bars'}`} onClick={_ => setIsNavbarMobile(!isNavbarMobile)}></i>
                    </nav>
                </div>
            </header>
        </>
    );
}


export default Header;