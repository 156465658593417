
import Constants from '../../data/Constants';
import Translations from '../../data/Translations';
import './style.css';

const Footer = ({currentLanguage}) => {
    const showIframeGoogleMaps = () => {
        if (Constants.address.values.googleMapsLink) {
            return (
                <iframe src={Constants.address.values.googleMapsLink} className="googlemaps" allowfullscreen="" loading="lazy"/>
            );
        }

        return false;
    }

    return (
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>{Translations.about[currentLanguage]}</h2>
                        <p>{Translations.aboutDescription[currentLanguage]}</p>
                    </div>

                    <div className="row">

                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i className={Constants.address.icon}></i>
                                    <h4>{Constants.address.text[currentLanguage]}:</h4>

                                    <p className="mb-0">{Constants.address.values.street[currentLanguage]}</p>
                                    <p>{Constants.address.values.city} - {Constants.address.values.country[currentLanguage]}</p>
                                </div>

                                <div className="email">
                                    <i className={Constants.contact.email.icon}></i>
                                    <h4>{Constants.contact.email.text[currentLanguage]}:</h4>
                                    <p>{Constants.contact.email.value}</p>
                                </div>

                                <div className="phone">
                                <i className={Constants.contact.phone.icon}></i>
                                    <h4>{Constants.contact.phone.text[currentLanguage]}:</h4>
                                    <p>{Constants.contact.phone.value}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                            {
                                showIframeGoogleMaps()
                            }
                        </div>

                    </div>

                </div>
            </section>

            <footer id="footer">
                
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6 footer-links">
                                <h3>{Constants.enterpriseName}</h3>
                                <p>
                                    {Constants.address.values.street[currentLanguage]} <br/>
                                    {Constants.address.values.city} <br/>
                                    {Constants.address.values.country[currentLanguage]} <br/><br/>
                                    
                                    <strong>{Constants.contact.phone.text[currentLanguage]}:</strong> {Constants.contact.phone.value}<br/>
                                    <strong>{Constants.contact.email.text[currentLanguage]}:</strong> {Constants.contact.email.value}<br/>
                                </p>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>{Translations.internalLinks[currentLanguage]}</h4>
                                <ul>
                                    <li> <i className="fas fa-chevron-right" /> <a className="nav-link scrollto" href="#topbar"> {Translations.home[currentLanguage]} </a></li>
                                    <li> <i className="fas fa-chevron-right" /> <a className="nav-link scrollto" href="#services"> {Translations.services[currentLanguage]} </a></li>
                                    <li> <i className="fas fa-chevron-right" /> <a className="nav-link scrollto" href="#bedrooms"> {Translations.accommodations[currentLanguage]} </a></li>
                                    <li> <i className="fas fa-chevron-right" /> <a className="nav-link scrollto " href="#about"> {Translations.about[currentLanguage]} </a></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-12 footer-links">
                                <h4>{Translations.ourSocialNetworks[currentLanguage]}</h4>
                                <p>{Translations.ourSocialNetworksDescription[currentLanguage]}</p>
                                
                                <div className="social-links mt-3">
                                    {
                                        Constants.socialLinks.filter(x => x.toShow).map((item, index) => {
                                            return (
                                                <a href={item.link} className={item.name} title={item.name} key={index} target="_blank">
                                                    <i className={item.icon} />
                                                </a>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container footer-bottom clearfix">
                    <div class="enterprise">
                        <strong>{Constants.enterpriseName}</strong>
                    </div>

                    <div class="copyright">
                        {Translations.copyright[currentLanguage]}
                    </div>

                    <br/>
                    
                    <div className="copyright mt-1">
                        {Constants.developer.text[currentLanguage]} <a href={Constants.developer.link} target="_blank">{Constants.developer.name[currentLanguage]}</a>
                    </div>
                </div>
            </footer>
        </>
    );
}


export default Footer;