import './style.css';

import { useRef } from 'react';
import LazyLoad from "react-lazyload";


const LazyImage = (props) => {
  const refPlaceholder = useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  return (
    <>
      <div className="lazyload-placeholder" ref={refPlaceholder} />
      <LazyLoad>
        <img
            onLoad={removePlaceholder}
            onError={removePlaceholder}
            src={props.src}
            alt={props.alt || ""}
            className="img-fluid" />
      </LazyLoad>
    </>
  );
};

export default LazyImage;