
import Constants from '../../data/Constants';
import Translations from '../../data/Translations';
import './style.css';

const FloatingActionBar = ({currentLanguage}) => {
    return (
        <div className='floatingActionBar'>
            {
                Constants.socialLinks.filter(item => item.toShowOnFloatingActionBar).map((item, index) => {
                    return (
                        <a href={item.link} key={index} target='_blank' title={item.name} className={`action-item social-link ${item.name.toLowerCase()}`}>
                            <i className={item.icon} />
                        </a>
                    );
                })
            }
        </div>
    );
}


export default FloatingActionBar;